import "../../App.css";
import DownloadIcon from "@mui/icons-material/Download";
// import LearningCode from "../../assets/images/learn-coding-clipart.svg";
import * as LottiePlayer from "@lottiefiles/lottie-player";

export default function About() {
  const getDate = () => {
    let dob = new Date("08/23/1982");
    let month_diff = Date.now() - dob.getTime();
    let age_dt = new Date(month_diff);
    let year = age_dt.getUTCFullYear();
    let age = Math.abs(year - 1970);
    return age;
  };
  return (
		<div
			id="about"
			className="min-h-screen bg-white flex flex-col text-center gap-5 text-black text-lg  font-normal">
			<div className="head text-5xl mt-12 font-bold" data-aos={"slide-down"}>
				About Me
			</div>
			<div className="flex flex-row  gap-6 ml-8">
				<div className="flex-col mt-14 flex flex-auto w-64 gap-6">
					<h3 className="text-3xl font-medium" data-aos={"fade-left"}>
						Soy <span className="text-yellow-600">Rubén Montes,</span> Fronted
						developer
					</h3>
					<p
						className="pt-5 leading-7 text-slate-900 text-justify"
						data-aos={"fade-left"}>
						¡Hola! Soy un apasionado desarrollador frontend con experiencia en
						la creación de aplicaciones web interactivas y atractivas. Mi
						objetivo es combinar diseño y funcionalidad para ofrecer una
						experiencia de usuario excepcional. Tengo {getDate()} años.Me
						especializo en tecnologías como HTML, CSS y JavaScript, y tengo
						experiencia en el uso de frameworks como React y Angular.js. Además,
						tengo conocimientos en el desarrollo responsive y la optimización de
						rendimiento.
					</p>
					
				</div>

				<div
					class="relative flex-auto w-32   sm:rounded-lg  pl-4"
					data-aos={"slide-left"}>
					{/* <img
            src={LearningCode}
            alt="Learning Code"
            className="motion-safe:animate-zoomy"
          /> */}
					<div className="motion-safe:animate-zoomy">
						<lottie-player
							autoplay
							loop
							mode="bounce"
							src="https://assets10.lottiefiles.com/packages/lf20_w98qte06.json"
							style={{ width: "350px" }}
						/>
					</div>
					<button className="py-3 mt-5 text-white mx-auto px-9 bg-blue-600 border-2 w-fit border-blue-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms] hover:bg-blue-800 hover:scale-[1.023] focus:bg-blue-800 animate-zoomy">
						<a
							href={require("../../assets/files/CV-RUBENMONTES.pdf")}
							download={"cv.pdf"}>
							Download CV
							<DownloadIcon />
						</a>
					</button>
				</div>
			</div>
		</div>
	);
}
