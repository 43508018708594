import BarGraph from "./BarGraph";
import DownloadIcon from "@mui/icons-material/Download";

export default function Resume() {
  return (
		<div id="resume" className="min-h-screen flex-col text-center">
			<div className="text-5xl font-bold py-8" data-aos={"slide-down"}>
				Resume
			</div>
			<div className="grid grid-cols-2 gap-7 mt-7">
				<ol class="relative border-l  border-gray-700 ml-9 mb-6">
					<li class="mb-10" data-aos="zoom-in">
						<span class="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
							<svg
								aria-hidden="true"
								class="w-3 h-3  text-blue-300"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
									clip-rule="evenodd"></path>
							</svg>
						</span>
						<h3 class="flex items-center mb-1 text-lg font-semibold  text-white ml-28">
							Diplomatura Programador Full stack Javascript UTN
						</h3>
						<time class="block pt-1 pb-3  text-sm font-normal leading-none  text-gray-500">
							Completed on July 7th, 2023
						</time>
						<p class="mb-4 text-base font-normal  text-gray-400">
							La diplomatura Full Stack en JavaScript es un programa de
							formación que te brinda los conocimientos y habilidades necesarios
							para convertirte en un desarrollador Full Stack en el lenguaje de
							programación JavaScript. Durante el curso, aprenderás tanto el
							desarrollo de aplicaciones web en el lado del cliente (Front-end)
							como en el lado del servidor (Back-end) utilizando JavaScript.
							Esto implica aprender frameworks y tecnologías populares como
							Node.js, Express.js, React.js, y MongoDB, entre otros. En el
							programa, te familiarizarás con los conceptos fundamentales de la
							programación web, como HTML, CSS y JavaScript, así como con los
							principios de diseño y desarrollo de bases de datos. También
							aprenderás cómo implementar características avanzadas, como la
							autenticación de usuarios, la creación de APIs y la gestión de
							bases de datos. Al completar esta diplomatura, estarás preparado
							para desarrollar aplicaciones web completas, trabajando tanto en
							el lado del cliente como en el lado del servidor. Esto te
							permitirá construir sitios web interactivos y dinámicos, así como
							aplicaciones web más complejas. Es importante destacar que esta
							diplomatura se centra en el desarrollo web utilizando JavaScript
							como lenguaje principal, y te proporcionará las bases necesarias
							para construir aplicaciones web modernas y escalables.
						</p>
						<a
							href="../../assets/files/Certificado-Utn.pdf"
							download
							target="_blank"
							rel="noreferrer"
							class="inline-flex items-center px-4 py-2 mt-2 text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700">
							<svg
								class="w-4 h-4 mr-2"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
									clip-rule="evenodd"></path>
							</svg>
							Download Certificate
						</a>
					</li>
					<li class="mb-10" data-aos="zoom-out" data-aos-delay="700">
						<span class="absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-gray-900 bg-blue-900">
							<svg
								aria-hidden="true"
								class="w-3 h-3 text-blue-300"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
									clip-rule="evenodd"></path>
							</svg>
						</span>
						<h3 class="mb-1 text-lg font-semibold text-white">
							<br />
							Fundamentos del Cloud Computing - Clases del Bootcamp de
							Multicloud
						</h3>
						<time class="block mb-2 text-sm font-normal leading-none text-gray-500">
							Completed on May 28th, 2022
						</time>
						<p class="text-base font-normal text-gray-400">
							El Cloud Computing, también conocido como computación en la nube,
							es un modelo de entrega de servicios de computación a través de
							internet. En lugar de tener que instalar y mantener
							infraestructura física localmente, las organizaciones pueden
							utilizar recursos informáticos, como servidores, almacenamiento y
							software, proporcionados por proveedores de servicios en la nube.
						</p>
						<a
							href="../../assets/files/cloud.pdf"
							download
							target="_blank"
							rel="noreferrer"
							class="inline-flex items-center px-4 py-2 mt-5 text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700">
							<svg
								class="w-4 h-4 mr-2"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
									clip-rule="evenodd"></path>
							</svg>
							Download Certificate
						</a>
					</li>
					<li data-aos="zoom-in" data-aos-offset={"-15"} data-aos-delay="1300">
						<span class="absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-gray-900 bg-blue-900">
							<svg
								aria-hidden="true"
								class="w-3 h-3 text-blue-300"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
									clip-rule="evenodd"></path>
							</svg>
						</span>
						<h3 class="mb-1 text-lg font-semibold text-white">
							Curso Profesional Desarrollo Web
						</h3>
						<time class="block mb-2 text-sm font-normal leading-none text-gray-500">
							Completed on December 9, 2022
						</time>
						<p class="text-base font-normal text-gray-400">
							El Curso Profesional de Desarrollo Web es un programa completo
							diseñado para enseñarte las habilidades necesarias para
							convertirte en un desarrollador web profesional. A lo largo del
							curso, aprenderás los fundamentos de HTML, CSS y JavaScript, así
							como también explorarás frameworks y bibliotecas populares como
							React.js y Node.js.
						</p>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.freecodecamp.org/certification/Daniel_Jebarson/front-end-development-libraries"
							class="inline-flex items-center px-4 py-2 mt-5 text-sm font-medium border rounded-lg focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700">
							<svg
								class="w-4 h-4 mr-2"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
									clip-rule="evenodd"></path>
							</svg>{" "}
							Download Certificate
						</a>
					</li>
				</ol>

				<div className="flex flex-col text-center" data-aos="slide-up">
					<div className="text-3xl font-bold py-3">My Skills</div>
					<div className="ml-16 flex flex-col gap-8 mt-6">
						<BarGraph name={"JavaScript"} percent={65} />
						<BarGraph name={"React"} percent={75} />
						<BarGraph name={"Angular Js"} percent={60} />
						<BarGraph name={"Ionic Framework"} percent={80} />
						<BarGraph name={"HTML/CSS"} percent={85} />
						<BarGraph name={"SQL"} percent={65} />
						<BarGraph name={"React Native"} percent={40} />
					</div>

					<a
						href={require("../../assets/files/CV-RUBENMONTES.pdf")}
						download={"Resume"}
						class="inline-flex items-center px-6 py-2 mt-12 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700">
						Download CV <DownloadIcon />
					</a>
				</div>
			</div>
		</div>
	);
}
