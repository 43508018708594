export const projects = [
	{
		title: "Ecommerce deportivo",
		desc: "Éste es un eccommerce echo en vanilla javascript para un proyexto de la universidad 3 de febrero, tiene localstorage, tiene su propia api de donde trae los productos, y simula una compra",
		tags: [
			{
				name: "Vanilla JS",
				color: "red-600",
			},
			{
				name: "BootstrapCSS",
				color: "green-600",
			},
		],
		img: "https://zmszgmmzcdolvhbxrskm.supabase.co/storage/v1/object/public/media/media/portfolio/Captura%20de%20pantalla%202023-11-03%20a%20la(s)%2016.17.01.png?t=2023-11-03T19%3A22%3A25.760Z",
		link: "https://untref-sports.netlify.app/",
		code: "https://github.com/rubenmontes58/INTREGRADOR-UNTREF-JS",
	},
	{
		title: "Radio Chilanguita EEUU",
		desc: "App Progresiva echa en Angular, el cliente queria que su radio se escuchara tanto en un iphone como en un android, asi que optamos por una pwa, que funciona perfecta, contiene un chat para los aoyentes streaming en vivo, y un mensajero directo con el operador de la radio para pedir temas.",
		tags: [
			{
				name: "AngularJS",
				color: "red-600",
			},
			{
				name: "BootstrapCSS",
				color: "green-600",
			},
		],
		img: "https://zmszgmmzcdolvhbxrskm.supabase.co/storage/v1/object/public/media/media/portfolio/Captura%20de%20pantalla%202023-11-03%20a%20la(s)%2016.28.40.png?t=2023-11-03T19%3A30%3A35.746Z",
		link: "https://chilanguita-demo.netlify.app/",
		code: "https://github.com/rubenmontes58/radio-chilanguita",
	},
	{
		title: "Radio La fregona Mexico",
		desc: "Para éste proyecto el cliente queria que cada tema que sonaba en la radio el oyente tenia que ver el nombre del tema en la app, fue realizado ionic y angular, y tambien se optó por una pwa.  ",
		tags: [
			{
				name: "Ionic Framework",
				color: "red-600",
			},
			{
				name: "AngularJS",
				color: "green-600",
			},
			{
				name: "BootstrapCss",
				color: "yellow-600",
			},
		],
		img: "https://zmszgmmzcdolvhbxrskm.supabase.co/storage/v1/object/public/media/media/portfolio/Captura%20de%20pantalla%202023-11-03%20a%20la(s)%2016.36.36.png?t=2023-11-03T19%3A39%3A10.129Z",
		link: "https://lafregonafm.netlify.app/",
		code: "https://github.com/rubenmontes58/fmlafregona",
	},
	{
		title: "Ecomerce Informatico",
		desc: "Este es un ecommerce echo en javascript para la academia coderhouse, tiene localstorage y toma los datos d una api",
		tags: [
			{
				name: "BootstrapCss",
				color: "red-600",
			},
			{
				name: "Javascript",
				color: "green-600",
			},
			{
				name: "Html",
				color: "blue-800",
			},
		],
		img: "https://zmszgmmzcdolvhbxrskm.supabase.co/storage/v1/object/public/media/media/portfolio/Captura%20de%20pantalla%202023-11-03%20a%20la(s)%2016.43.24.png?t=2023-11-03T19%3A46%3A39.068Z",
		link: "https://tercer-entrega-coder-js.netlify.app/",
		code: "https://github.com/rubenmontes58/tercerentregacoderjs",
	},
	
];
